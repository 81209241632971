import PropTypes from 'prop-types';
import {gql} from '@apollo/client';
import {BREADCRUMB_FIELDS} from 'fragments/Breadcrumb';
import {ITEM_FIELDS} from 'fragments/Item';
import useQuery from 'hooks/useQuery';

const GET_ITEM_WITH_RESPONSES = gql`
  query GetItemWithResponses($ref: String!, $key: String!, $numResponses: Int) {
    item(ref: $ref, key: $key) {
      ${ITEM_FIELDS}
      breadcrumbs {
        ${BREADCRUMB_FIELDS}
      }
      document {
        documentSnapshot(version: "latest") {
          inputs {
            id
            label
          }
          processSteps {
            id
            title
          }
        }
        responses(numResponses: $numResponses) {
          id
          ref
          key
          documentId
          documentVersion
          data
          textData
          responseNum
          urlName
          createdAt
          createdBy {
            email
            name
          }
          updatedAt
          updatedBy {
            email
            name
          }
          responseSubmission {
            createdAt
          }
        }
      }
    }
  }
`;

useItemWithResponsesQuery.propTypes = {
  ref: PropTypes.string,
  key: PropTypes.string,
};

function useItemWithResponsesQuery (props) {
  const {
    ref,
    key,
    numResponses,
  } = props;

  const variables = {
    ref,
    key,
    numResponses: numResponses ?? undefined,
  };

  const {loading, error, data} = useQuery(GET_ITEM_WITH_RESPONSES, {
    fetchPolicy: 'no-cache',
    variables,
  });

  return {
    item: data && data.item ? data.item : null,
    loading,
    error,
  };
}

export default useItemWithResponsesQuery;
