import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import tw, {styled} from 'twin.macro';
import ReactTable from 'components/ReactTable';
import {navigate} from '@reach/router';
import getFriendlyDate from 'utils/getFriendlyDate';
import DocumentToolbar from 'containers/DocumentToolbar';
import Breadcrumbs from 'components/Breadcrumbs';
import PageTitle from 'components/PageTitle';
import Truncate from 'components/Truncate';
import {useItem} from 'providers/ItemProvider';
import {ITEM_TYPE} from 'am-constants';
import {useWorkspace} from 'containers/WorkspaceApp/providers/WorkspaceProvider';
import Button, {ButtonSpacer} from 'components/Button';

ResponseTablePage.propTypes = {
  inputs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  processSteps: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  responses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    ref: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    data: PropTypes.shape({}).isRequired,
  })).isRequired,
  printMode: PropTypes.bool.isRequired,
  numResponses: PropTypes.number,
  setNumResponses: PropTypes.func.isRequired,
};

function ResponseTablePage (props) {
  const {
    inputs,
    responses,
    processSteps,
    printMode,
    numResponses,
    setNumResponses,
  } = props;

  const hasMoreResponses = typeof numResponses === 'number' && numResponses === responses.length;

  const columns = getColumns(inputs);
  const {urlName: workspaceUrlName} = useWorkspace();

  const {item} = useItem();
  const {
    breadcrumbs,
    name,
    itemTypeId,
  } = item;

  const data = responses
    .map((response) => {
      const title = `${name} ${response.responseNum}`;
      const url = `/${workspaceUrlName}/use/${response.urlName}`;

      const truncatedTextAnswers = getTruncatedTextAnswers(response.textData);
      const textAnswerSortVals = getTextAnswersForSort(response.textData);
      const numCompletedSteps = processSteps.filter((processStep) => !!response.data[processStep.id]).length;

      return {
        title,
        status: <Tag green={!!response.responseSubmission}>{response.responseSubmission ? 'Completed' : 'In progress'}</Tag>,
        statusSortVal: response.responseSubmission ? 'Completed' : 'In progress',
        onClick: () => navigate(url),
        stepsCompleted: <StepsCompleted>
          <Steps>{processSteps.map((processStep) => <Step key={`step${processStep.id}`} completed={!!response.data[processStep.id]} />)}</Steps>
          {numCompletedSteps} of {processSteps.length}
        </StepsCompleted>,
        stepsCompletedSortVal: numCompletedSteps,
        lastUpdatedAt: response.updatedAt || response.createdAt,
        lastUpdate: getFriendlyDate(response.updatedAt || response.createdAt),
        lastUpdateBy: response.updatedBy || response.createdBy,
        ...truncatedTextAnswers,
        ...textAnswerSortVals,
      };
    })
    .sort((a, b) => b.lastUpdatedAt - a.lastUpdatedAt);

  return (
    <>
      {!printMode && (
        <DocumentToolbar
          showDeleteItemMenu
          showExportItemMenu
          showMoveItemMenu
          showShareItemMenu
        />
      )}
      <Wrapper printMode={printMode}>
        {!printMode && breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
        <PageTitle>{name}</PageTitle>
        {responses.length > 0 && <Overflow><ReactTable columns={columns} data={data} /></Overflow>}
        {hasMoreResponses && (
          <ButtonContainer>
            Showing most recent {numResponses} responses
            <ButtonSpacer />
            <Button primary onClick={() => setNumResponses(numResponses + 50)}>Show more</Button>
            <Button onClick={() => setNumResponses(null)}>Show all</Button>
          </ButtonContainer>
        )}
        {!responses.length && itemTypeId === ITEM_TYPE.CHECKLIST && 'No one has used this checklist (yet!)'}
        {!responses.length && itemTypeId === ITEM_TYPE.FORM && 'No one has used this form (yet!)'}
      </Wrapper>
    </>
  );
}

function getColumns (inputs) {
  const standardColumns = [
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Status',
      accessor: 'status',
      sortType: useMemo(() => (rowA, rowB, columnId, desc) => (rowA.original.statusSortVal > rowB.original.statusSortVal ? 1 : -1), []),
    },
    {
      Header: 'Steps completed',
      accessor: 'stepsCompleted',
      sortType: useMemo(() => (rowA, rowB, columnId, desc) => (rowA.original.stepsCompletedSortVal < rowB.original.stepsCompletedSortVal ? 1 : -1), []),
    },
    {
      Header: 'Last update',
      accessor: 'lastUpdate',
      sortType: useMemo(() => (rowA, rowB, columnId, desc) => (rowA.original.lastUpdatedAt < rowB.original.lastUpdatedAt ? 1 : -1), []),
    },
    {
      Header: 'Last update by',
      accessor: 'lastUpdateBy.name',
    },
  ];

  const inputColumns = inputs.map((input) => ({
    Header: input.label,
    accessor: input.id,
    // Sort so blank values always go to the bottom
    sortType: useMemo(() => (rowA, rowB, columnId, desc) => {
      const rowAVal = (rowA.original[`${input.id}_sort`] || '').trim();
      const rowBVal = (rowB.original[`${input.id}_sort`] || '').trim();
      let ret;

      if (!rowAVal.length) {
        ret = -1;
      }
      else if (!rowBVal.length) {
        ret = 1;
      }
      else if (desc) {
        ret = rowAVal.localeCompare(rowBVal, 'en');
      }
      else {
        ret = -1 * rowAVal.localeCompare(rowBVal, 'en');
      }

      if (desc) {
        return ret;
      }

      return -1 * ret;
    }, []),
  }));

  return [
    ...standardColumns,
    ...inputColumns,
  ];
}

function getTruncatedTextAnswers (textData) {
  return Object.entries(textData).reduce((acc, [id, value]) => ({
    ...acc,
    [id]: <Truncate>{value}</Truncate>,
  }), {});
}

function getTextAnswersForSort (textData) {
  return Object.entries(textData).reduce((acc, [id, value]) => ({
    ...acc,
    [`${id}_sort`]: value,
  }), {});
}

const Overflow = styled.div((props) => [
  tw`
    overflow-scroll
    print:overflow-visible
  `,
]);

const Wrapper = styled.div.attrs({
  'data-test-id': 'response-table-wrapper',
})((props) => [
  tw`
    px-5
    mx-auto
  `,
  !props.printMode && tw`
  `,
]);

const Tag = styled.div((props) => [
  tw`
    text-2xs
    bg-blue-500
    rounded
    py-1
    px-1.5
    font-bold
    text-white
    inline-block
  `,
  props.green && tw`
    bg-green-500
  `,
]);

const StepsCompleted = styled.div((props) => [
  tw`
    flex
    items-center
    text-xs
    text-gray-500
  `,
]);

const Steps = styled.div((props) => [
  tw`
    mr-1
    flex
  `,
]);

const Step = styled.div((props) => [
  tw`
    w-3
    h-3
    bg-gray-200
    mr-px
    first:rounded-l-sm
    last:rounded-r-sm
  `,
  props.completed && tw`
    bg-green-500
  `,
]);

const ButtonContainer = styled.div((props) => [
  tw`
    flex
    items-center
    gap-2
    pb-4
    pt-4
  `,
]);

export default ResponseTablePage;
