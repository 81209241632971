import {nanoid} from 'nanoid';

const uniqueIdElementTypes = [
  'checkbox',
  'help-block',
  'form-group',
  'choice',
  'process',
  'process-step',
  'image',
];

const uniqueIdElementWrappers = [
  'process-step-list',
];

const makeApply = (editor, setSelection) => {
  const {apply} = editor;

  return (operation) => {
    let updatedOperation = operation;
    updatedOperation = addUniqueId(updatedOperation);
    updatedOperation = resetState(updatedOperation);

    apply(updatedOperation);

    // Update the selection state. Needed so menus can respond immediately to
    // the selection changing.
    if (operation.type === 'set_selection') {
      setSelection(editor.selection);
    }
  };
};

function addUniqueId (operation) {
  const {
    newProperties,
    node,
    properties,
    type: operationType,
  } = operation;

  if (
    operationType === 'insert_node' &&
    [
      ...uniqueIdElementTypes,
      ...uniqueIdElementWrappers,
    ].includes(node.type)
  ) {
    return {
      ...operation,
      node: {
        ...addUniqueIdsToNode(node),
      },
    };
  }

  if (
    operationType === 'set_node' &&
    uniqueIdElementTypes.includes(newProperties.type)
  ) {
    return {
      ...operation,
      newProperties: {
        ...newProperties,
        id: nanoid(),
      },
    };
  }

  if (
    operationType === 'split_node' &&
    !!properties.id
  ) {
    // The new properties will use the existing node's id. We need to replace it
    // with a new id.
    return {
      ...operation,
      properties: {
        ...properties,
        id: nanoid(),
      },
    };
  }

  return operation;
}

function addUniqueIdsToNode (node) {
  if (node.type === 'process') {
    return {
      ...node,
      id: nanoid(),
      children: [
        node.children[0], // process-title
        addUniqueIdsToNode(node.children[1]), // process-step-list
      ],
    };
  }

  if (node.type === 'process-step-list') {
    return {
      ...node,
      children: node.children.map((childNode) =>
        addUniqueIdsToNode(childNode)),
    };
  }

  if (node.type === 'process-step') {
    return {
      ...node,
      id: nanoid(),
      children: [
        node.children[0], // process-step-title
        { // process-step-content
          ...node.children[1],
          children: node.children[1].children.map((childNode) =>
            addUniqueIdsToNode(childNode)),
        },
      ],
    };
  }

  if (node.type === 'form-group') {
    return {
      ...node,
      id: nanoid(),
      children: node.children.map((childNode) =>
        addUniqueIdsToNode(childNode)),
    };
  }

  if (node.type === 'choice-group') {
    return {
      ...node,
      children: node.children.map((childNode) =>
        addUniqueIdsToNode(childNode)),
    };
  }

  if (uniqueIdElementTypes.includes(node.type)) {
    return {
      ...node,
      id: nanoid(),
    };
  }

  return node;
}

function resetState (operation) {
  const {
    newProperties,
    node,
    properties,
    type: operationType,
  } = operation;

  if (
    operationType === 'insert_node' &&
    uniqueIdElementTypes.includes(node.type) &&
    node.checked
  ) {
    return {
      ...operation,
      node: {
        ...node,
        checked: false,
      },
    };
  }

  if (
    operationType === 'set_node' &&
    uniqueIdElementTypes.includes(newProperties.type) &&
    newProperties.checked
  ) {
    return {
      ...operation,
      newProperties: {
        ...newProperties,
        checked: false,
      },
    };
  }

  if (
    operationType === 'split_node' &&
    !!properties.id &&
    properties.checked
  ) {
    return {
      ...operation,
      properties: {
        ...properties,
        checked: false,
      },
    };
  }

  return operation;
}

export default makeApply;
