import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import {Helmet} from 'react-helmet';
import getNameRefAndKeyParts from 'utils/getNameRefAndKeyParts';
import {useTopbar} from 'providers/TopbarProvider';
import useURLSearchParam from 'hooks/useURLSearchParam';
import useItemWithResponsesQuery from './hooks/useItemWithResponsesQuery';
import ResponseTablePage from './components/ResponseTablePage';

ResponseTable.propTypes = {
  workspaceUrlName: PropTypes.string,
  'name--:ref-:key': PropTypes.string,
  printMode: PropTypes.bool,
};

ResponseTable.defaultProps = {
  printMode: false,
};

function ResponseTable (props) {
  const {
    'name--:ref-:key': nameRefAndKey,
    printMode,
  } = props;

  const itemsParam = useURLSearchParam('items');
  const isValidItemParam = getIsValidItemParam(itemsParam);
  const [numResponses, setNumResponses] = useState(isValidItemParam ? getItemCount(itemsParam) : 50);

  useEffect(() => {
    if (!isValidItemParam) {
      updateItemsParam(50);
      setNumResponses(50);
    }
  }, [isValidItemParam]);

  const updateNumResponses = (newValue) => {
    updateItemsParam(newValue);
    setNumResponses(newValue);
  };

  const {
    ref: itemRef,
    key: itemKey,
  } = useMemo(() => getNameRefAndKeyParts(nameRefAndKey), [nameRefAndKey]);

  const {setPrintMode} = useTopbar();
  useEffect(() => {
    setPrintMode(printMode);
  });

  const {
    item,
    loading,
    error,
  } = useItemWithResponsesQuery({ref: itemRef, key: itemKey, numResponses});

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>Could not load responses</p>;
  }

  if (!item.document) {
    return <p>This item is not a page.</p>;
  }

  if (!item.document.documentSnapshot) {
    return <p>This page has not been published</p>;
  }

  return <>
    <Helmet>
      <title>Submissions</title>
    </Helmet>
    <ResponseTablePage
      responses={item.document.responses}
      inputs={item.document.documentSnapshot.inputs}
      processSteps={item.document.documentSnapshot.processSteps}
      printMode={printMode}
      numResponses={numResponses}
      setNumResponses={updateNumResponses}
    />
  </>;
}

function updateItemsParam (value) {
  const url = new URL(window.location.href);
  const paramValue = value ?? 'all';

  url.searchParams.set('items', paramValue);
  window.history.replaceState(null, null, url.href);
}

function getItemCount (itemsParam) {
  if (itemsParam === 'all') {
    return null;
  }

  return parseInt(itemsParam, 10);
}

function getIsValidItemParam (itemsParam) {
  if (!itemsParam) {
    return false;
  }

  if (itemsParam === 'all') {
    return true;
  }

  const number = parseInt(itemsParam, 10);
  const isInteger = Number.isInteger(number);
  const isPositive = number > 0;

  return isInteger && isPositive;
}

export default ResponseTable;
