import React, {
  useCallback, useEffect, useLayoutEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import {Slate, Editable, ReactEditor} from 'slate-react';
import {DragDropContext} from 'react-beautiful-dnd';
import makeOnFocus from './editable/makeOnFocus';
import makeOnKeyDown from './editable/makeOnKeyDown';
import makeOnMouseDown from './editable/makeOnMouseDown';
import makeOnChange from './editor/makeOnChange';
import makeIsVoid from './editor/makeIsVoid';
import EditableWrapper from './EditableWrapper';
import makeNormalizeNode from './editor/makeNormalizeNode';
import renderElement from './editable/renderElement';
import renderLeaf from './editable/renderLeaf';
import makeInsertData from './editor/makeInsertData';
import makeIsInline from './editor/makeIsInline';
import makeInsertText from './editor/makeInsertText';
import makeApply from './editor/makeApply';
import SlateEditorProvider from './providers/SlateEditorProvider';
import makeOnDragEnd from './dragdrop/makeOnDragEnd';
import checkCanSelectionBeTransformed from './utils/checkCanSelectionBeTransformed';
import getSelectionMeta from './utils/getSelectionMeta';
import isLinkActive from './utils/isLinkActive';
import makeSetFragmentData from './editor/makeSetFragmentData';
import HelpBlockToolbar from './toolbars/HelpBlockToolbar';
import makeOnCut from './editable/makeOnCut';
import FooterToolbar from './toolbars/FooterToolbar';
import TextFormattingToolbar from './toolbars/TextFormattingToolbar';
import ImageFormattingToolbar from './toolbars/ImageFormattingToolbar';
import FormGroupToolbar from './toolbars/FormGroupToolbar';

SlateEditor.propTypes = {
  data: PropTypes.shape({}),
  content: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  documentManagingSelection: PropTypes.bool,
  // We need to pass editor into SlateEditor as a prop (rather than setting it
  // up here) to avoid the editor crashing when using hot module reloading.
  editor: PropTypes.shape({
    apply: PropTypes.func,
    insertData: PropTypes.func,
    insertText: PropTypes.func,
    isInline: PropTypes.func,
    isVoid: PropTypes.func,
    normalizeNode: PropTypes.func,
    setFragmentData: PropTypes.func,
    selectionMeta: PropTypes.object,
  }).isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  recalculateWarningsAndPositions: PropTypes.func,
  selection: PropTypes.shape({}),
  selectedElements: PropTypes.shape({
    ids: PropTypes.array.isRequired,
    inRange: PropTypes.bool,
  }).isRequired,
  selectElements: PropTypes.func.isRequired,
  selectElementsInRange: PropTypes.func.isRequired,
  setSelection: PropTypes.func,
  uploadBase64Image: PropTypes.func.isRequired,
};

SlateEditor.defaultProps = {
  documentManagingSelection: false,
  readOnly: false,
  setSelection: () => {},
};

function SlateEditor (props) {
  const {
    content,
    documentManagingSelection,
    editor,
    onChange: handleChange,
    readOnly,
    recalculateWarningsAndPositions,
    selection,
    selectedElements,
    selectElements,
    selectElementsInRange,
    setSelection,
    uploadBase64Image,
  } = props;

  // We can only wrap these functions in useMemo if the functions aren't
  // using values on editor that change (e.g. selection, content).
  // TODO: Update functions so we pass in the selection and content and can wrap
  // the functions in useMemo.
  // TODO: Extract all of this into a useEditor hook that creates the editor,
  // and perhaps apply our updates before wrapping the editor in the withHistory
  // plugin.
  editor.apply = useMemo(() => makeApply(editor, setSelection), []);
  editor.insertData = makeInsertData(editor, {selectedElements, uploadBase64Image});
  editor.insertText = useMemo(() => makeInsertText(editor), []);
  editor.isInline = useMemo(() => makeIsInline(editor), []);
  editor.isVoid = useMemo(() => makeIsVoid(editor), []);
  editor.normalizeNode = makeNormalizeNode(editor);
  editor.setFragmentData = makeSetFragmentData(editor, {selectedElements});
  // readOnly check needed to stop the onSelect handlers from firing
  // TODO: Update other areas to stop unnecessary logic from running when in
  // readOnly mode (e.g. onCut etc)
  const onChange = readOnly ? () => {} : makeOnChange(editor, {
    selectElements,
    selectElementsInRange,
    selectedElements,
  }, handleChange);
  const onCut = makeOnCut(editor, {selectedElements, selectElements});
  const onDragEnd = makeOnDragEnd(editor);
  const onFocus = makeOnFocus(editor);
  const onKeyDown = makeOnKeyDown(editor, {selectedElements, selectElements});
  const onMouseDown = useMemo(() => makeOnMouseDown({selectElements}), []);
  const renderElementCallback = useCallback(renderElement);
  const renderLeafCallback = useCallback(renderLeaf);

  const selectionMeta = getSelectionMeta(editor);
  const {
    selectionStart,
    selectionStartAncestors,
    selectionStartElement,
    isSelectionCollapsed,
    isSelectionInSingleElement,
  } = selectionMeta;

  useEffect(() => {
    if (!readOnly && recalculateWarningsAndPositions) {
      const focusedStepId = getFocusedStepId(selectionStartElement, editor);

      // The reason we do this twice is to 1) instantly move the warnings when adding
      // steps, deleting paragraphs, etc. and 2) move the warnings after animations (
      // opening/closing expanding elements) have finished. The max time for a step opening
      // or closing is 300ms, so we wait 305ms.
      recalculateWarningsAndPositions({focusedStepId});
      setTimeout(() => {
        recalculateWarningsAndPositions({focusedStepId});
      }, 305);
    }
  }, [content, readOnly]);

  useEffect(() => {
    if (!readOnly && selectionStartElement) {
      const focusedStepId = getFocusedStepId(selectionStartElement, editor);

      recalculateWarningsAndPositions({focusedStepId});
    }
  }, [selectionStartElement, readOnly]);

  useEffect(() => {
    try {
      const firstElement = content[1];
      const domElement = ReactEditor.toDOMNode(editor, firstElement);
      domElement.setAttribute('id', 'first-element');
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error setting id on first element', error);
    }
  }, [content]);

  useEffect(() => {
    ReactEditor.focus(editor);
  }, []);

  if (!content) {
    return null;
  }

  const isBlockSelected = selectedElements.ids.length > 0;
  const showTextFormattingToolbar =
    !readOnly &&
    !isBlockSelected &&
    selectionStartElement &&
    [
      'checkbox',
      'heading1',
      'heading2',
      'heading3',
      'label',
      'list-item',
      'ordered-list-item',
      'paragraph',
    ].includes(selectionStartElement.type);

  const [focused, setFocused] = useState();

  const showImageFormattingToolbar =
    !readOnly &&
    focused &&
    !isBlockSelected &&
    selectionStartElement &&
    selectionStartElement.type === 'image' &&
    !!selectionStartElement.url &&
    isSelectionInSingleElement;

  const showFormGroupToolbar =
    !readOnly &&
    focused &&
    !isBlockSelected &&
    selectionStartElement &&
    selectionStartAncestors.some((ancestor) => ancestor.type === 'form-group') &&
    isSelectionCollapsed;

  const [imageNode, setImageNode] = useState();
  const [formGroupNode, setFormGroupElement] = useState();
  const [inputElement, setInputElement] = useState();
  const [helpBlockNode, setHelpBlockNode] = useState();
  useLayoutEffect(() => {
    if (showImageFormattingToolbar && selectionStartElement) {
      setImageNode(ReactEditor.toDOMNode(editor, selectionStartElement).querySelector('img'));
    }
    else {
      setImageNode(undefined);
    }

    if (showFormGroupToolbar && selectionStartElement) {
      const formGroupElement = selectionStartAncestors.find((ancestor) => ancestor.type === 'form-group');
      setFormGroupElement(ReactEditor.toDOMNode(editor, formGroupElement));
      setInputElement(formGroupElement.children[1]);
    }
    else {
      setFormGroupElement(undefined);
      setInputElement(undefined);
    }

    if (showHelpBlockFormattingMenu && selectionStartElement) {
      const helpBlockElement = selectionStartAncestors.find((ancestor) => ancestor.type === 'help-block');
      setHelpBlockNode(ReactEditor.toDOMNode(editor, helpBlockElement));
    }
    else {
      setHelpBlockNode(undefined);
    }
  }, [selectionStartElement]);

  const showFooterToolbar =
    !readOnly &&
    selectionStart &&
    isSelectionCollapsed &&
    checkCanSelectionBeTransformed(editor) &&
    selectionStartElement.type !== 'input';
  const selectionInsideHelpBlock = selectionStart && selectionStartAncestors.some((n) => n.type === 'help-block');
  const selectionInsideProcess = selectionStart && selectionStartAncestors.some((n) => n.type === 'process');
  const selectionInsideFormGroup = selectionStart && selectionStartAncestors.some((n) => n.type === 'form-group');
  const showHeadingOptions = !selectionInsideHelpBlock && !selectionInsideProcess && !selectionInsideFormGroup;
  const showInputOptions = !selectionInsideHelpBlock && !selectionInsideFormGroup;
  const showListOptions = !selectionInsideFormGroup;
  const showHelpBlockOption = !selectionInsideHelpBlock;
  const showProcessOption = !selectionInsideHelpBlock && !selectionInsideProcess;
  const showHelpBlockFormattingMenu = !readOnly && selectionStart && selectionStartElement && selectionStartElement.type === 'help-block-title';

  const slateSelectionProps = documentManagingSelection ? {selection} : {};
  return (
    <SlateEditorProvider editor={editor}>
      <DragDropContext
        onDragEnd={onDragEnd}>
        <Slate
          editor={editor}
          onChange={onChange}
          value={content}
          {...slateSelectionProps}>
          {showImageFormattingToolbar && imageNode && (
            <ImageFormattingToolbar imageNode={imageNode} />
          )}
          {showTextFormattingToolbar && (
            <TextFormattingToolbar
              isLinkActive={isLinkActive(editor)}
              showHeadingOptions={showHeadingOptions}
              showInputOptions={showInputOptions}
              showListOptions={showListOptions}
            />
          )}
          {showFormGroupToolbar && formGroupNode && inputElement && (
            <FormGroupToolbar
              formGroupNode={formGroupNode}
              inputElement={inputElement} />
          )}
          {showFooterToolbar && (
            <FooterToolbar
              showHelpBlockOption={showHelpBlockOption}
              showInputOptions={showInputOptions}
              showProcessOption={showProcessOption}
              uploadBase64Image={uploadBase64Image}
            />
          )}
          {showHelpBlockFormattingMenu && helpBlockNode &&
              <HelpBlockToolbar helpBlockNode={helpBlockNode} />
          }
          <EditableWrapper>
            <Editable
              contentEditable={!isBlockSelected}
              onDragStart={(event) => event.preventDefault()}
              onBlur={() => setFocused(false)}
              onCut={onCut}
              onFocus={(e) => {
                setFocused(true);
                onFocus(e);
              }}
              onKeyDown={onKeyDown}
              onMouseDown={onMouseDown}
              readOnly={readOnly}
              renderElement={renderElementCallback}
              renderLeaf={renderLeafCallback}
              // Overiding scrollSelectionIntoView fixes really annoying
              // behavior where the editor jumps around when expanding and
              // collapsing elements, and jumps to the top fo teh document if
              // the selection ever becomes null.
              // Fix was suggested in Slate Slack community:
              // https://slate-js.slack.com/archives/C1RH7AXSS/p1638244402031600?thread_ts=1638239117.030400&cid=C1RH7AXSS
              scrollSelectionIntoView={() => {}}
              style={{counterReset: 'step-counter'}}
            />
          </EditableWrapper>
        </Slate>
      </DragDropContext>
    </SlateEditorProvider>
  );
}

function getFocusedStepId (selectionStartElement, editor) {
  const type = selectionStartElement?.type;

  if (type === 'process-step-title') {
    const domElement = ReactEditor.toDOMNode(editor, selectionStartElement);
    const parentWithId = domElement?.closest('.warning-locator');
    return parentWithId?.getAttribute('id');
  }

  if (type === 'title') {
    const domElement = ReactEditor.toDOMNode(editor, selectionStartElement);
    const childWithId = domElement?.querySelector('.warning-locator');
    return childWithId?.getAttribute('id');
  }

  return null;
}

export default React.memo(SlateEditor);
